<script>
import Layout from "../../layouts/main";
import PageHeader from "@/components/page-header";
import appConfig from "@/app.config";
import Swal from "sweetalert2";

import {
  required,
  email
} from "vuelidate/lib/validators";

/**
 * Form Validation component
 */
export default {
  name: 'contact',
  page: {
    title: "Contacts",
    meta: [{ label: "description", content: appConfig.description }]
  },
  components: { Layout, PageHeader },
  data() {
    return {
      title: "Contacts",
      items: [
        {
          text: "Home",
          href: "/home"
        },
        {
          text: "Contacts",
          active: true
        }
      ],
      form: {
        phone: null,
        email: null,
        fax: null,
        address: null,
      },
      typesubmit: false,
      isError: false,
      errorMessage: null,
    };
  },
  validations: {
    form: {
      phone: { required },
      email: { required, email },
      fax: { required },
    }
  },
  mounted() {
    this.fetchContact()
  },
  methods: {
    fetchContact() {
      this.$axios.get('/footer')
        .then( response => {
          let contact = response.data.data
          this.form.fax = contact.fax
          this.form.phone = contact.telp.substr(3)
          this.form.email = contact.email
          this.form.address = contact.address
        })
    },
    onSubmit(e) {
      this.typesubmit = true;
      // stop here if form is invalid
      this.$v.$touch();
      if (this.$v.$invalid) {
        this.isError = true
        this.errorMessage = "please fill the form with valid data"
      } else {

        let param = new FormData();
        param.append('id', 1) 
        param.append('fax', this.form.fax) 
        param.append('telp', "+62" + this.form.phone)
        param.append('email', this.form.email)
        param.append('address', "")
        
        this.$axios.post('/footer', param)
        .then( (response) => { 
          console.log(response.data)
          Swal.fire("Success", "Contacts Updated Successfully", "success");
        }).catch(e => {
          if (e.response) {  
            this.isError = true
            this.errorMessage = e.response.data.message
          }
        })
      }
    },
  }
};
</script>

<template>
  <Layout>
    <PageHeader :title="title" :items="items" />
    <div class="row">
      <div class="col-lg-12">
        <div class="card">
          <div class="card-body">
            <b-alert
              v-model="isError"
              variant="danger"
              class="mt-3"
              dismissible
            >{{errorMessage}}</b-alert>
            <form action="#" @submit.prevent="onSubmit">
              <div class="form-group">
                <label>Phone</label>
                <b-input-group prepend="+62">
                  <b-form-input 
                    v-model="form.phone"
                    type="number"
                    class="form-control"
                    name="phone"
                    :class="{ 'is-invalid': typesubmit && $v.form.phone.$error }">
                  </b-form-input>
                  <div v-if="typesubmit && $v.form.phone.$error" class="invalid-feedback">
                    <span v-if="!$v.form.phone.required">This value is required.</span>
                  </div>
                </b-input-group>
              </div>

              <div class="form-group">
                <label>E-Mail</label>
                <div>
                  <input
                    v-model="form.email"
                    type="email"
                    name="email"
                    class="form-control"
                    :class="{ 'is-invalid': typesubmit && $v.form.email.$error }"
                  />
                  <div v-if="typesubmit && $v.form.email.$error" class="invalid-feedback">
                    <span v-if="!$v.form.email.required">This value is required.</span>
                    <span v-if="!$v.form.email.email">This value should be a valid email.</span>
                  </div>
                </div>
              </div>

              <div class="form-group">
                <label>Fax</label>
                <input
                  v-model="form.fax"
                  type="text"
                  class="form-control"
                  name="fax"
                  :class="{ 'is-invalid': typesubmit && $v.form.fax.$error }"
                />
                <div v-if="typesubmit && $v.form.fax.$error" class="invalid-feedback">
                  <span v-if="!$v.form.fax.required">This value is required.</span>
                </div>
              </div>

              <div class="form-group mb-0">
                <div>
                  <button type="submit" class="btn btn-primary">Save Change</button>
                </div>
              </div>
            </form>
          </div>
          <!-- end card-body -->
        </div>
      </div>
      <!-- end col-->
    </div>
    <!-- end row -->
  </Layout>
</template>